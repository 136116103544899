import { Component, OnInit } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Password } from 'primeng/password';
import { ChangePasswordWidgetComponent } from '../../../../../../auth/src/lib/page/change-password-widget/change-password-widget.component';

@Component({
  selector: 'gc-change-password',
  standalone: true,
  imports: [ReactiveFormsModule, ChangePasswordWidgetComponent],
  templateUrl: './change-password.component.html',
  styleUrl: './change-password.component.sass'
})
export class ChangePasswordComponent implements OnInit {


  ngOnInit(): void { }
}
