import { Directive, ElementRef, EventEmitter, HostListener, Input, Output, Renderer2 } from '@angular/core';

@Directive({
  selector: '[gcClickOutside]',
  standalone: true
})
export class ClickOutsideDirective {
  @Input() outSideClick!: boolean;
  @Output() clickOutside = new EventEmitter<void>();

  constructor(private elementRef: ElementRef, private renderer: Renderer2) { }

  @HostListener('document:click', ['$event.target'])
  onClick(targetElement: HTMLElement) {
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (!clickedInside && this.outSideClick) {
      console.log('clicked outside');
      this.clickOutside.emit();
    }
  }
}