import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterOutlet } from '@angular/router';
import { AlertService, CoreService, EventBusType } from '@gcCore';
import { AuthService } from 'auth';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { Subject, takeUntil } from 'rxjs';
import { FooterComponent } from './layout/footer/footer.component';
import { HeaderComponent } from './layout/header/header.component';

@Component({
  selector: 'gc-root',
  standalone: true,
  providers: [MessageService, AlertService],
  imports: [RouterOutlet, HeaderComponent, FooterComponent, ToastModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.sass'
})
export class AppComponent {
  title = 'gc';
  window!: Window;
  destroy$: Subject<boolean> = new Subject<boolean>();
  isLoggedIn: boolean | undefined;
  constructor(private eventBusService: CoreService,
    private auth: AuthService,
    private titleService: Title,
  ) { }

  ngOnInit() {
    this.checkAccessToken();
    this.subscribeForEvents();
    this.setMetaTags();
  }

  subscribeForEvents() {
    this.eventBusService.eventsBC.onmessage = event => {
      // if (event.data.type === EventBC.SESSION_CHANGED) {
      //   this.window.location.reload();
      // }
    };

    this.eventBusService.events$.pipe(takeUntil(this.destroy$)).subscribe(event => {
      if (event.type === EventBusType.FINISH_LOGOUT) {
        // this.closeSessionAndReload(event);
      }
    });

    // this.router.events.pipe(takeUntil(this.destroy$)).subscribe(event => {
    //   this.handleRouteEvent(event);
    // });

    // this.window.onfocus = () => {
    //   this.checkAccessToken();
    // };
  }

  checkAccessToken() {
    return this.auth.isAuthenticated();
    // if (!accessToken) {
    //   this.auth.logout();
    // }
  }
  setMetaTags() {
    this.titleService.setTitle(this.title);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
