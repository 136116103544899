<p-toast #toast [baseZIndex]="5000" position="top-right" [preventOpenDuplicates]="true"
    [breakpoints]="{'920px': {width: '100%', right: '0', left: '0'}}" />
<gc-header />
<div class="content">
    <div class="mt-10 ">
        <router-outlet></router-outlet>
    </div>
    <gc-footer />
    <p-toast [baseZIndex]="5000" position="top-right" closable="false" [preventOpenDuplicates]="true"
        [breakpoints]="{'920px': {width: '100%', right: '0', left: '0'}}">
    </p-toast>
</div>