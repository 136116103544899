<!-- header -->
<header class="header position-fixed">
    <!-- START Navigation -->
    <div class="navigation-bar" [class.fixed-me]="isNavbarFixed">
        <div class="container-lg">
            <div class="bg-white">
                <nav class="navbar navbar-expand-lg navbar-light navbar-reset justify-content-between">
                    <a class="logo" [routerLink]="['/']">
                        <img class="logo-default" src="images/logo.png" alt="Give charity logo">
                    </a>
                    <div class="d-inline-flex">
                        <div class="navbar-collapse collapse" id="gcNavbar">
                            <ul class="navbar-nav ml-auto">
                                @for (item of menu; track $index) {
                                <li class="nav-item " [routerLinkActive]="['active']">
                                    <a class="nav-link" [routerLink]="['/', item.url]">{{item.title}}</a>
                                </li>
                                }
                            </ul>
                        </div>
                        @if (isLoggedIn) {
                        <div class="dropdown profile-dropdown" (click)="showDropDown = !showDropDown">
                            <div>
                                <img class="head-profile-box" src="{{userImgUrl}}">
                            </div>
                            <button class="btn-toggle dropdown-toggle">
                                <span><i class="fa pi pi-chevron-down"></i></span>
                            </button>
                            <!-- login -->
                            <ul class="dropdown-menu" [class.show]="showDropDown" gcClickOutside
                                [outSideClick]="showDropDown" (clickOutside)="showDropDown = false">
                                <!-- <li class="align-center">{{loggedUser?.firstName}} {{loggedUser?.middleName}}
                                    {{loggedUser?.lastName}}</li> -->
                                @for (item of loginMenu; track $index) {
                                <li class="center">
                                    <a [routerLink]="item.url? [ '/', item.url]:null" (click)="onMenuClick(item)">
                                        <i class="mdi"><img style="width: 20px;" src="images/svg/{{item.icon}}.svg" />
                                        </i>
                                        {{item.title}}
                                    </a>
                                </li>
                                }
                                <li class="center"> <a (click)="logout()" href="javascript:void(0)">
                                        <i class="mdi"><img style="width: 20px;" src="images/svg/logout.svg" /> </i>
                                        Logout
                                    </a>
                                </li>
                            </ul>
                        </div>
                        } @else {
                        <div class="button-login">
                            <a [routerLink]="['/', loginUrl]" class="btn-pink btn">Sign In</a>
                        </div>
                        }
                        <div>
                            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#gcNavbar"
                                aria-controls="gcNavbar" aria-expanded="false" aria-label="Toggle navigation">
                                <span class="navbar-toggler-icon"></span>
                            </button>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    </div>
    <!-- END Navigation -->
</header>
<!-- /header -->