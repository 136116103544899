<footer class="footer-area">
    <div class="container-lg">
        <div class="row">
            <div class="col-lg-4 col-md-12 pb-5">
                <div pAnimateOnScroll enterClass="fadeinright" leaveClass="fadeout" class="single-footer-widget">
                    <img src="images/footer-logo.png" alt="Give charity logo"><br />
                    <p>GiveCharity is a crowdfunding platform to raise funds for Education and Medical Treatments.</p>
                    <ul class="social-list">
                        <li>
                            <a href="https://www.facebook.com/GiveCharity-112483280466895/" target="_blank">
                                <img src="images/icon/facebookIco.png" alt="Facebook" />
                            </a>
                        </li>
                        <li>
                            <a href="https://twitter.com/givecharity_GC" target="_blank">
                                <img src="images/icon/twitterIco.png" alt="Twitter" />
                            </a>
                        </li>
                        <li>
                            <a href="https://www.instagram.com/givecharity_GC/" target="_blank">
                                <img src="images/icon/instaIco.png" alt="Insta" />
                            </a>
                        </li>
                        <li>
                            <a href="https://www.linkedin.com/in/GiveCharity" target="_blank">
                                <img src="images/icon/linkedinIco.png" alt="Linkedin" />
                            </a>
                        </li>
                        <li>
                            <a href="https://wa.me/917367955955?text=Hi" target="_blank">
                                <img src="images/icon/whatsappIco.png" alt="whatsapp" />
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 pb-5">
                <div class="single-footer-widget fadeInUp">
                    <h3>Give Charity</h3>
                    <ul class="list">
                        @for (item of menu; track $index) {
                        <li><a [routerLink]="['/', item.url]">{{item.title}}</a></li>
                        }
                        <!-- <li *ngIf="!isLoggedIn"><a [routerLink]="['/', urlService.ADMIN_APP.USER_LOGIN_URL]">Login</a>
                        </li> -->
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 pb-5">
                <div class="single-footer-widget">
                    <h3>Categories </h3>
                    <ul class="list">
                        @for (c of category_data; track $index) {
                        <li>
                            <a href="javascript:void(0)" (click)="goToCategory(c)">{{c.categoryName}}</a>
                        </li>
                        }
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6 pb-5">
                <div class="single-footer-widget">
                    <h3>Support</h3>
                    <ul class="list">
                        @for (m of supportMenu; track $index) {
                        <li><a [routerLink]="['/', m.url]">{{m.title}}</a></li>
                        }
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="copyright-area container-lg">
            <div class="row justify-content-center align-items-center">
                <div class="col-md-6">
                    <p>Copyright {{'@'}} {{today.getFullYear()}} GiveCharity. All rights reserved</p>
                </div>
                <div class="col-md-6">
                    <ul>
                        <li>
                            <a [routerLink]="['/', termsUrl]" target="_blank">Terms &amp; Conditions</a>
                        </li>
                        <li>
                            <a [routerLink]="['/',privacyUrl]" target="_blank">Privacy Policy</a>
                        </li>
                    </ul>
                </div>
            </div>
    </div>
    <!-- <div class="elfsight-app-cabdc708-7b37-4441-8b5c-ca1bb9700b5e"></div> -->
</footer>