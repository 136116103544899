import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { environment } from '@env';
import { apiInterceptor, AuthService, errorInterceptor, StorageService } from 'auth';
import { MessageService } from 'primeng/api';
import { DialogService, } from 'primeng/dynamicdialog';
import { routes } from './app.routes';

export const appConfig: ApplicationConfig = {
  providers: [provideZoneChangeDetection({ eventCoalescing: true }), provideRouter(routes),
  provideHttpClient(withInterceptors([apiInterceptor, errorInterceptor])),
  provideAnimations(),
  {
    useValue: 'API_URL',
    provide: environment.API_URL,
  },
    AuthService,
    StorageService,
    MessageService,
    DialogService,
  ]
};