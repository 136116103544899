import { Component } from '@angular/core';
import { AnimateOnScrollModule } from 'primeng/animateonscroll';
import { Router, RouterModule } from '@angular/router';
import { AuthService } from 'auth';
import { ApiService } from '@gcWeb/module/service/api.service';
import { PageUrl } from '@gcWeb/shared/service/urlHelper';
@Component({
  selector: 'gc-footer',
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.sass',
  standalone: true,
  imports: [AnimateOnScrollModule, RouterModule]
})
export class FooterComponent {
  today = new Date();
  category_data: any;
  termsUrl = PageUrl.DEFAULT.TERMS_SERVICE;
  privacyUrl = PageUrl.DEFAULT.PRIVACY_POLICY;
  menu = [
    { title: 'Donate', url: PageUrl.DEFAULT.BROWSE_CAMPAIGN },
    { title: 'Fundraise', url: PageUrl.DEFAULT.DONEE_REQUEST },
    { title: 'Education Grants', url: PageUrl.DEFAULT.EDUCATION_GRANTS },
    { title: 'Tax Benefits', url: PageUrl.DEFAULT.TAX_BENEFITS },
    { title: 'Blog', url: PageUrl.DEFAULT.BLOGS },
    { title: 'Login', url: PageUrl.AUTH.LOGIN }
  ];
  supportMenu = [
    { title: 'About', url: PageUrl.DEFAULT.ABOUT },
    { title: 'Contact', url: PageUrl.DEFAULT.CONTACT_US },
    { title: 'How It Works', url: PageUrl.DEFAULT.HOW_IT_WORKS },
  ]
  browseUrl = PageUrl.DEFAULT.BROWSE_CAMPAIGN;
  isLoggedIn: boolean = false
  constructor(private auth: AuthService, private apiService: ApiService,
    private router: Router
  ) { }

  ngOnInit() {
    this.getCategory();
    this.isLoggedIn = this.auth.isAuthenticated();
  }

  getCategory() {
    if (!this.category_data) {
      this.apiService.getCategory().then((data) => this.category_data = data);
    }
  }
  goToCategory(c: any) {
    this.router.navigate(['/', this.browseUrl], { queryParams: { category: c.categoryName } });
  }
}