import { Component, HostListener } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { EventBusType } from '@gcCore';
import { CoreService } from '@gcCore';
import { AuthService } from 'auth';
import { User } from 'auth';
import { ClickOutsideDirective } from '@gcWeb/shared/directive/click-outside.directive';
import { PageUrl } from '@gcWeb/shared/service/urlHelper';
import { ModalService } from '@gcWeb/shared/service/modal.service';
import { ChangePasswordComponent } from '@gcWeb/auth/pages/change-password/change-password.component';
import { MySubscriptionComponent } from '@gcWeb/module/user/pages/my-subscription/my-subscription.component';
import { ApiService } from '@gcWeb/module/service/api.service';
import { UserProfile } from '@gcWeb/types';
declare const window: any

interface LoginMenu {
  title: string,
  url?: string,
  icon?: string,
  id: string
}


@Component({
  selector: 'gc-header',
  standalone: true,
  imports: [RouterModule, ClickOutsideDirective],
  templateUrl: './header.component.html',
  styleUrl: './header.component.sass'
})
export class HeaderComponent {
  // window!: Window;
  destroy$: Subject<boolean> = new Subject<boolean>();
  isLoggedIn: boolean | undefined;
  menu: any = [];
  loggedUser!: User | null;
  isNavbarFixed: boolean = false;
  defUrl = PageUrl.DEFAULT;
  loginUrl = PageUrl.AUTH.LOGIN
  showDropDown: boolean = false
  userImgUrl: string | undefined = 'images/icon/user.png'
  loginMenu: LoginMenu[] = [
    { title: 'Dashboard', url: PageUrl.USER.DASHBOARD, icon: 'dashboard', id: 'DASHBOARD' },
    { title: 'Profile', url: PageUrl.USER.MY_PROFILE, icon: 'user', id: 'PROFILE' },
    { title: 'My Request', url: PageUrl.USER.DONEE_REQUEST_LIST, icon: 'request', id: 'REQUEST' },
    { title: 'My Subscription ', url: PageUrl.USER.MY_SUBSCRIPTION, icon: 'user', id: 'MY_SUBSCRIPTION' },
    { title: 'My Campaign', url: PageUrl.USER.MY_CAMPAIGN, icon: 'campaign', id: 'MY_CAMPAIGN' },
    { title: 'My Payout', url: PageUrl.USER.MY_PAYOUT, icon: 'campaign', id: 'MY_PAYOUT' },
    { title: 'My Donation', url: PageUrl.USER.MY_DONATION, icon: 'donation', id: 'DONATION' },
    { title: 'Change Password', icon: 'change-password', id: 'CHANGE_PASSWORD' },
  ];

  constructor(
    private eventBusService: CoreService,
    private auth: AuthService,
    private router: Router,
    private modalService: ModalService,
    private apiService: ApiService
  ) { }

  @HostListener('window:scroll', ['$event']) onScroll() {
    if (window.scrollY > 100) {
      this.isNavbarFixed = true;
    } else {
      this.isNavbarFixed = false;
    }
  }


  ngOnInit() {
    this.checkLogin();
    this.subscribeForEvents();
    this.menu = [{ title: 'Home', url: this.defUrl.HOME },
    { title: 'Donate', url: this.defUrl.BROWSE_CAMPAIGN },
    { title: 'Fundraise', url: this.defUrl.DONEE_REQUEST },
    { title: 'About', url: this.defUrl.ABOUT },
    // { title: 'Tax Benefits', url: this.defUrl.TAX_BENEFITS },
    { title: 'Contact', url: this.defUrl.CONTACT_US },
    ];
  }

  logout() {
    this.auth.logout(PageUrl.AUTH.LOGIN);
  }


  onMenuClick(item: LoginMenu) {
    if (item.id === 'CHANGE_PASSWORD') {
      this.modalService.openModal(ChangePasswordComponent, { header: "Change Password" })?.then((res) => {
      })
    }
  }

  subscribeForEvents() {
    this.eventBusService.eventsBC.onmessage = event => {
      // if (event.data.type === EventBC.SESSION_CHANGED) {
      //   this.window.location.reload();
      // }
    };

    this.eventBusService.events$.pipe(takeUntil(this.destroy$)).subscribe(event => {
      if (event.type === EventBusType.FINISH_LOGOUT) {
        // this.closeSessionAndReload(event);
      }
    });

    this.router.events.pipe(takeUntil(this.destroy$)).subscribe(event => {
      this.handleRouteEvent(event);
    });

    window.onfocus = () => {
      this.checkLogin();
    };
    window.click = () => {
      this.checkLogin();
    };
  }
  handleRouteEvent(ev: any) {
    this.isLoggedIn = this.checkLogin();
  }

  checkLogin() {
    this.isLoggedIn = this.auth.isAuthenticated();
    return this.isLoggedIn;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
