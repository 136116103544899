import { NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { PublicBreadcrumbComponent } from '../../../module/public/component/public-breadcrumb/public-breadcrumb.component';
import { PageUrl } from '../../../shared/service/urlHelper';
import { LoginWidgetComponent } from 'auth';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [RouterModule, ReactiveFormsModule, NgIf, PublicBreadcrumbComponent, LoginWidgetComponent],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass']
})
export class LoginComponent {
  privacyUrl = PageUrl.DEFAULT.PRIVACY_POLICY;
  termsUrl = PageUrl.DEFAULT.TERMS_SERVICE;
  signUp = PageUrl.DEFAULT.SIGNUP;
  forgotUrl = PageUrl.AUTH.FORGOT;
  data: string | undefined
  constructor(private router: Router, private route: ActivatedRoute,) { }
  onComplete(ev: any) {
    if (ev.status == 'SUCCESS') {
      this.route.queryParams.subscribe((params: { data?: 'string' }) => {
        this.data = params['data']
      })
      if (this.data == 'request') {
        this.router.navigate(['/', PageUrl.DEFAULT.DONEE_REQUEST]);
      } else {
        this.router.navigate(['/', PageUrl.USER.DASHBOARD]);
      }
    }
  }
}