import { Routes } from '@angular/router';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { LoginComponent } from './auth/pages/login/login.component';
import { PageUrl } from './shared/service/urlHelper';
import { ForgotPasswordComponent } from './auth/pages/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './auth/pages/change-password/change-password.component';

export const routes: Routes = [
    { path: PageUrl.AUTH.LOGIN, component: LoginComponent, canActivate: [] },
    { path: PageUrl.AUTH.FORGOT, component: ForgotPasswordComponent },
    { path: PageUrl.AUTH.CHANGE_PASSWORD, component: ChangePasswordComponent },
    {
        path: '',
        loadChildren: () => import('./module/public/public.module').then(mod => mod.PublicModule),
    },
    {
        path: '',
        loadChildren: () => import('./module/user/user.module').then(mod => mod.UserModule),
    },
    { path: PageUrl.NOT_FOUND, component: NotFoundComponent },
    { path: '**', component: NotFoundComponent }
];