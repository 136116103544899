import { Component } from '@angular/core';
import { PublicBreadcrumbComponent } from '../../module/public/component/public-breadcrumb/public-breadcrumb.component';

@Component({
  selector: 'gc-not-found',
  standalone: true,
  imports:[PublicBreadcrumbComponent],
  templateUrl: './not-found.component.html',
  styleUrl: './not-found.component.sass'
})
export class NotFoundComponent {

}
